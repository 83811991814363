<template>
  <a-modal width="50%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onClose" @cancel="onClose" @close="onClose">
    <a-card  :bordered="false" :title="$t('销售订单.物流详情')"
             :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
             :bodyStyle="{'background-color':'#fbfbfb'}">
      <table class="info-table">
        <tr>
          <td class="first">{{$t('销售订单.订单编号')}}：</td>
          <td class="data">
            {{ this.record.orderId}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.物流公司')}}：</td>
          <td class="data">
            {{ this.record.logisticsName}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.物流单号')}}：</td>
          <td class="data">
            {{ this.record.logisticsNo}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.寄件人手机号')}}：</td>
          <td class="data">
            {{this.record.deliveryMobile}}
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('物流公司信息.公司logo')}}：</td>
          <td class="data">
            <img v-if="record.logisticsLogo" :src="record.logisticsLogo"  style="width:60px;height:62px;" alt="none"/>
          </td>
        </tr>
        <tr>
          <td class="first">{{$t('销售订单.物流详情')}}：</td>
          <td class="data">
            <template>
              <a-timeline>
                <a-timeline-item v-for="(item, index) in this.record.progressList">
                  <p>{{item.context}}</p>
                  <p>{{item.time}}</p>
                </a-timeline-item>
              </a-timeline>
            </template>
          </td>
        </tr>
      </table>
    </a-card>
  </a-modal>
</template>

<script>
import {logistics} from '@/api/order/sell'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";


export default {
  name: 'LogisticsDetail',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      logisticsList: [],
      record: {
        id:null,
        progressList: {},
      } ,
      // 1增加,2修改
      formType: 1,
      open: false,
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.record={
        id:null,
        progressList: {},
      }
    },
    /** 修改按钮操作 */
    handleDetail(id) {
      logistics({id: id}).then(response => {
        this.open = true
        this.record=response.data
      });
    },
  }
}
</script>
<style  lang="less" scoped>
.info-table {
  tr {
    height: 50px;
  }
  td {
    display: table-cell;
    vertical-align: top;
    font-size: 16px;
  }
  .first {
    width: 120px;
  }
  .data {
    width: 700px;
  }
}
</style>
